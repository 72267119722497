.about-section-container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 8rem 0;
  border-color: transparent;
  border-style: solid;
  border-width: 0 6vw;
  box-sizing: content-box;
  display: flex;
  gap: 8rem;
}

.about-header-container {
  display: flex;
  gap: 2.5rem;
  align-items: center;
}

.dash-icon {
  scale: 6;
  margin: 0 1.25rem 0 2rem;
  color: var(--clr-blue);
}

.header-title {
  font-size: 4rem;
  font-weight: 1000;
  color: var(--clr-blue);
  margin-bottom: 1.5rem;
}

.header-subtitle {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--clr-black);
  margin-bottom: 2rem;
}

.header-subtitle span {
  color: var(--clr-brightorange);
}

.about-text-container,
.about-image-container {
  width: calc((100% - 8rem) / 2);
}

.about-text-container p {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 3rem;
  margin-bottom: 1.5rem;
}

.about-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-animation {
  width: 110%;
  height: 110%;
  border: none;
  outline: none;
}

.link {
  color: var(--clr-blue);
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}

/* ********** MEDIA QUERIES ********** */

@media screen and (max-width: 900px) {
  .about-section-container {
    flex-direction: column;
    gap: 4rem;
  }

  .about-text-container,
  .about-image-container {
    width: 100%;
  }

  .about-image-container {
    height: 40rem;
  }
}

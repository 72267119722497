.section-background-container {
  background: linear-gradient(
    to left top,
    hsla(341, 93%, 94%, 0.812) 0%,
    var(--clr-white) 50%,
    hsla(186, 74%, 93%, 0.816) 100%
  );
}

.services-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  max-width: 120rem;
  margin: 0 auto;
  padding: 8rem 0;
  border-color: transparent;
  border-style: solid;
  border-width: 0 6vw;
  box-sizing: content-box;
}

.services-container,
.services-cards-container {
  width: calc((100% - 8rem) / 2);
  box-sizing: border-box;
}

.services-header-container {
  display: flex;
  gap: 2.5rem;
  align-items: center;
  padding-left: 1vw;
}

.dash-icon {
  scale: 4;
  color: var(--clr-brightorange);
}

.services-container h1 {
  font-size: 2.75rem;
  font-weight: 1000;
  color: var(--clr-brightorange);
}

.services-container h2 {
  font-size: 3rem;
  font-weight: 900;
  line-height: 8vh;
  color: var(--clr-black);
}

.services-container span {
  color: var(--clr-blue);
}

.services-container p {
  font-size: 1.6rem;
  line-height: 3.2rem;
  color: var(--clr-black);
}

.cleaning-products-container {
  align-items: center;
  justify-content: center;
}

.cleaning-products {
  padding: 4vh 3vw 4vh 0;
}

.services-cards-container {
  z-index: 2;
  position: relative;
  padding: 0 1.5vw;
}

.services-cards-container > *:not(:last-child) {
  margin-bottom: 4vh;
}

.green-number {
  background: var(--clr-green);
  color: var(--clr-white);
}

.blue-number {
  background: var(--clr-blue);
  color: var(--clr-white);
}

.orange-number {
  background: var(--clr-lightorange);
  color: var(--clr-white);
}

.services-cards-container::after {
  content: "";
  background-image: radial-gradient(var(--clr-blue) 20%, #fcfcfc00 20%);
  background-position: 0 0;
  background-size: 15px 15px;
  height: 100px;
  width: 150px;
  position: absolute;
  right: -1.5rem;
  bottom: -3rem;
  z-index: -1;
}

/* ********** MEDIA QUERIES ********** */

@media screen and (max-width: 1024px) {
  .services-container h2 {
    font-size: 2.5rem;
    line-height: 6vh;
  }

  .services-wrapper {
    gap: 6rem;
  }

  .services-container,
  .services-cards-container {
    width: calc((100% - 6rem) / 2);
  }
}

@media screen and (max-width: 900px) {
  .services-container h2 {
    font-size: 2.25rem;
    line-height: 5vh;
  }
}

@media screen and (max-width: 768px) {
  .services-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 8rem 0;
  }

  .services-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .cleaning-products-container {
    display: flex;
  }

  .cleaning-products {
    padding: 4vh 0 1vh 0;
    width: 75%;
  }

  .services-cards-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1rem;
  }

  .services-cards-container > *:not(:last-child) {
    margin-bottom: 0;
  }

  .services-cards-container::after {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .services-cards-container {
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
  }
}

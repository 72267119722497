.form-section-container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 8rem 0;
  border-color: transparent;
  border-style: solid;
  border-width: 0 6vw;
  box-sizing: content-box;
}

.contact-text-wrapper {
  text-align: center;
  margin-bottom: 2vh;
}

.contact-text-wrapper h2 {
  font-size: 4rem;
  font-weight: 900;
  padding-bottom: 1.5rem;
  text-transform: capitalize;
}

.contact-text-wrapper h3 {
  font-size: 2.25rem;
  font-weight: 800;
  color: var(--clr-darkblue);
}

.lower-section-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8rem;
  padding: 4vh 0;
  box-sizing: border-box;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  width: 55%;
}

.form-wrapper form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

label {
  font-size: 1.5rem;
  font-weight: 1000;
}

input,
textarea,
select {
  border: solid var(--clr-black) 0.15rem;
  border-radius: 5px;
  margin-bottom: 0.75rem;
  padding: 0.75rem;
  font-size: 1.25rem;
}

input {
  height: 3.5rem;
  background: var(--clr-white);
}

select {
  height: 4rem;
  background: var(--clr-white);
}

textarea {
  height: 20rem;
  background: var(--clr-white);
}

input:focus,
textarea:focus,
select:focus {
  outline: solid var(--clr-green) 0.3rem;
  border: none;
}

select:hover {
  cursor: pointer;
}

.form-submit-button-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.form-submit-button {
  background: var(--clr-green);
  color: var(--clr-white);
  background-image: linear-gradient(
    to bottom right,
    hsla(173, 58%, 39%, 0.75),
    hsla(194, 100%, 33%, 0.75)
  );
  border: none;
  cursor: pointer;
  margin-top: 1vh;
  height: 4rem;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 1000;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}

.form-submit-button:hover {
  background: var(--clr-darkblue);
  background-image: linear-gradient(
    to bottom right,
    hsla(194, 100%, 33%, 0.75),
    hsla(197, 37%, 24%, 0.75)
  );
}

.contact-details-wrapper {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.contact-details-wrapper h3 {
  font-size: 1.75rem;
  font-weight: 700;
  padding-bottom: 0.25rem;
}

.contact-details-wrapper p {
  font-size: 1.5rem;
  line-height: 2.5rem;
  padding-bottom: 1.5rem;
}

iframe {
  width: 100%;
  height: 100%;
  outline: solid var(--clr-darkblue) 0.1rem;
  border-radius: 5px;
  margin-bottom: 0.75rem;
}

/* ********** MEDIA QUERIES ********** */

@media screen and (max-width: 900px) {
  .lower-section-container {
    flex-direction: column;
    gap: 4rem;
  }

  .form-wrapper,
  .contact-details-wrapper {
    width: 100%;
  }

  .lower-section-container .form-wrapper {
    order: 2;
  }

  .contact-details-wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;
  }

  .contact-details-text-container {
    width: 85%;
  }

  iframe {
    height: 30vw;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .contact-details-wrapper {
    flex-direction: column;
    gap: 4rem;
  }

  .contact-details-text-container {
    width: 100%;
  }

  iframe {
    height: 35rem;
    margin-bottom: 4rem;
  }
}

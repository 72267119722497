.about-cards-container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 8rem 0 10rem 0;
  border-color: transparent;
  border-style: solid;
  border-width: 0 6vw;
  box-sizing: content-box;
}

.card-section-title {
  font-size: 2rem;
  font-weight: 700;
  color: var(--clr-darkblue);
  text-align: center;
  margin-bottom: 6rem;
  text-transform: capitalize;
}

.cards-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 3rem;
  place-content: center;
  box-sizing: border-box;
}

.card {
  border-radius: 7.5px;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.card-icon {
  width: 5rem;
  height: 5rem;
  color: var(--clr-brightorange);
  margin-bottom: 2rem;
}

.card-title {
  font-size: 2rem;
  font-weight: 1000;
  color: var(--clr-blue);
  margin-bottom: 1rem;
}

.card-text {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 2rem;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  box-sizing: border-box;
}

.about-card-button {
  cursor: pointer;
  border-radius: 15px;
  padding: 1.5rem;
  width: 20rem;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  transition: all 0.3s ease-in-out;
  background: var(--clr-green);
  color: var(--clr-white);
  background-image: linear-gradient(
    to bottom right,
    hsla(173, 58%, 39%, 0.75),
    hsla(194, 100%, 33%, 0.75)
  );
  border: none;
  transition: all 0.3s ease-in-out;
}

.about-card-button:hover {
  background: var(--clr-darkblue);
  background-image: linear-gradient(
    to bottom right,
    hsla(194, 100%, 33%, 0.75),
    hsla(197, 37%, 24%, 0.75)
  );
}

/* ********** MEDIA QUERIES ********** */
@media screen and (max-width: 1024px) {
  .cards-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .cards-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .about-card-button {
    width: 100%;
  }
}

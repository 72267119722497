.hero-section-container {
  background-image: url(../../../assets/images/landingBG1.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
}

.hero-wrapper {
  max-width: 120rem;
  margin: 0 auto;
  padding: 8rem 0;
  border-color: transparent;
  border-style: solid;
  border-width: 0 6vw;
  box-sizing: content-box;
}

.hero-container {
  text-align: center;
  box-sizing: border-box;
}

.hero-container h1,
.hero-container p {
  color: var(--clr-black);
}

.hero-container h1 {
  font-size: 5rem;
  font-weight: 800;
  line-height: 8rem;
  text-transform: capitalize;
}

.hero-container h2 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 8rem;
  color: var(--clr-darkblue);
}

.hero-container p {
  font-size: 1.6rem;
  line-height: 2.5rem;
  text-align: center;
  padding: 2vh 6vw;
}

.hero-buttons-wrapper {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2.5vh;
}

.hero-button-a,
.hero-button-b {
  cursor: pointer;
  border-radius: 15px;
  padding: 1.5rem;
  width: 15rem;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  transition: all 0.3s ease-in-out;
}

.hero-button-a {
  background: var(--clr-green);
  background-image: linear-gradient(
    to right,
    hsla(173, 58%, 39%, 0.75),
    hsla(194, 100%, 33%, 0.75)
  );
  color: var(--clr-white);
  border: solid 1.5px var(--clr-green);
  border-image: linear-gradient(
    to right,
    hsla(173, 58%, 39%, 0.75),
    hsla(194, 100%, 33%, 0.75)
  );
}

.hero-button-b {
  background: var(--clr-white);
  color: var(--clr-lightorange);
  border: solid 1.5px var(--clr-lightorange);
}

.hero-button-a:hover {
  background: var(--clr-darkblue);
  background-image: linear-gradient(
    to right,
    hsla(194, 100%, 33%, 0.75),
    hsla(197, 37%, 24%, 0.75)
  );
  border-image: linear-gradient(
    to right,
    hsla(194, 100%, 33%, 0.75),
    hsla(197, 37%, 24%, 0.75)
  );
  border: var(--clr-darkblue) solid 1.5px;
}

.hero-button-b:hover {
  background: var(--clr-lightorange);
  background-image: linear-gradient(
    to right,
    hsla(27, 87%, 67%, 0.75),
    hsla(12, 76%, 61%, 0.75)
  );
  border-image: linear-gradient(
    to right,
    hsla(27, 87%, 67%, 0.75),
    hsla(12, 76%, 61%, 0.75)
  );
  color: var(--clr-white);
}

.hero-lower-section-container {
  display: flex;
  justify-content: space-between;
  padding: 0 6vw 10rem 6vw;
  background-image: url(../../../assets/images/landingBG2.png);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 80%;
}

.hero-socials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
}

.hero-socials a {
  font-size: 2rem;
  border: solid 1.5px var(--clr-black);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--clr-black);
  transition: all 0.3s ease-in-out;
}

.hero-socials a:hover {
  border: var(--clr-green) solid 1.5px;
  color: var(--clr-green);
  transform: scale(1.15);
}

.hero-scroll-down-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 5rem;
  padding: 0.5vh 0;
  position: relative;
}

.scroll-down-icon {
  scale: 2.5;
}

.scroll-down-text {
  transform: rotate(270deg);
  font-size: 1.25rem;
  width: max-content;
  position: absolute;
  right: -3rem;
  top: 4.5rem;
}

/* ********** MEDIA QUERIES ********** */

@media screen and (max-width: 768px) {
  .hero-container h1 {
    font-size: 4rem;
    line-height: 6rem;
  }

  .hero-container h2 {
    font-size: 3rem;
    line-height: 6rem;
  }

  .hero-container p {
    padding: 1.5vh 6vw;
  }
}

@media screen and (max-width: 480px) {
  .hero-container h1 {
    font-size: 3rem;
    line-height: 5rem;
  }

  .hero-container h2 {
    font-size: 2rem;
    line-height: 4rem;
  }

  .hero-container p {
    padding: 1.5vh 5vw;
  }

  .hero-buttons-wrapper {
    flex-direction: column;
    gap: 2rem;
    margin-top: 2.5vh;
  }

  .hero-button-a,
  .hero-button-b {
    width: 100%;
  }
}

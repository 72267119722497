.projects-container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 10rem 0 15rem 0;
  border-color: transparent;
  border-style: solid;
  border-width: 0 6vw;
  box-sizing: content-box;
}

.projects-wrapper {
  box-sizing: border-box;
}

.projects-header-container {
  margin-bottom: 6rem;
  text-align: center;
}

.projects-header-container h2 {
  font-size: 3rem;
  font-weight: 1000;
}

.projects-header-container span {
  color: var(--clr-blue);
}

.projects-description-container {
  display: flex;
  flex-direction: row;
  gap: 8rem;
}

.projects-description-wrapper,
.projects-image-wrapper {
  width: calc((100% - 8rem) / 2);
}

.projects-description-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.projects-description-wrapper h3 {
  font-size: 2rem;
  font-weight: 900;
  color: var(--clr-black);
}

.projects-description-wrapper h3 span {
  color: var(--clr-brightorange);
}

.projects-description-wrapper p {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 3rem;
}

.projects-button {
  margin-top: 1.25rem;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  padding: 1.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background: var(--clr-lightorange);
  background-image: linear-gradient(
    to right,
    hsla(27, 87%, 67%, 0.75),
    hsla(12, 76%, 61%, 0.75)
  );
  color: var(--clr-white);
  border: none;
  transition: all 0.3s ease-in-out;
}

.projects-button:hover {
  background: var(--clr-yellow);
  background-image: linear-gradient(
    to right,
    hsla(43, 74%, 66%, 0.75),
    hsla(27, 87%, 67%, 0.75)
  );
}

.projects-image-wrapper {
  overflow: hidden;
  position: relative;
}

.projects-image {
  border-radius: 7.5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ********** MEDIA QUERIES ********** */

@media screen and (max-width: 1024px) {
  .projects-image {
    width: 100%;
    height: 47.5%;
    object-fit: cover;
  }

  .projects-image-wrapper::after {
    content: "";
    height: 47.5%;
    width: 100%;
    border-radius: 7.5px;
    background-image: url(../../../assets/images/project3.jpg);
    background-size: cover;
    background-position: bottom;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .projects-description-container {
    gap: 6rem;
  }

  .projects-description-wrapper,
  .projects-image-wrapper {
    width: calc((100% - 6rem) / 2);
  }
}

@media screen and (max-width: 768px) {
  .projects-description-container {
    flex-direction: column;
    gap: 4rem;
  }

  .projects-description-wrapper,
  .projects-image-wrapper {
    width: 100%;
  }

  .projects-description-container .projects-description-wrapper {
    order: 2;
  }

  .projects-image-wrapper::after {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .projects-header-container h2 {
    font-size: 2.5rem;
  }
}

.hero-image-wrapper {
  background-image: linear-gradient(
      to right,
      hsla(173, 58%, 39%, 0.75),
      hsla(194, 100%, 33%, 0.75)
    ),
    url(../../../assets/images/cleaningSurface.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  height: 50vh;
  position: relative;
  background-size: cover;
  margin-bottom: 6vh;
  clip-path: polygon(0 0, 100% 0, 100% 40vh, 0 100%);
  text-align: center;
  opacity: 0.75;
}

.page-title {
  display: block;
  width: 100%;
  color: var(--clr-white);
  text-transform: uppercase;
  font-size: 7rem;
  font-weight: 1000;
  letter-spacing: 0.15rem;
  position: absolute;
  top: 42.5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

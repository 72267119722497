.project-section-container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 8rem 0;
  border-color: transparent;
  border-style: solid;
  border-width: 0 6vw;
  box-sizing: content-box;
  display: flex;
  gap: 8rem;
}

.project-header-container {
  display: flex;
  gap: 2.5rem;
  align-items: center;
}

.dash-icon {
  scale: 6;
  margin: 0 1.25rem 0 2rem;
  color: var(--clr-blue);
}

.header-title {
  font-size: 4rem;
  font-weight: 1000;
  color: var(--clr-blue);
  margin-bottom: 1.5rem;
}

.header-subtitle {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--clr-black);
  margin-bottom: 2rem;
}

.header-subtitle span {
  color: var(--clr-brightorange);
}

.project-text-container,
.project-image-container {
  width: calc((100% - 8rem) / 2);
}

.project-text-container p {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 3rem;
  margin-bottom: 1.5rem;
}

.project-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-image-text-one,
.project-image-text-two {
  font-size: 2.5rem;
  font-weight: 900;
  margin: 3rem 0;
  text-transform: capitalize;
  text-align: center;
  color: var(--clr-black);
}

.project-image-text-one span {
  color: var(--clr-green);
}

.project-image-text-two span {
  color: var(--clr-yellow);
}

/* ********** MEDIA QUERIES ********** */

@media screen and (max-width: 900px) {
  .project-section-container {
    flex-direction: column;
    gap: 4rem;
  }

  .project-text-container,
  .project-image-container {
    width: 100%;
  }
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4vh 6vw;
}

.responsive-nav-logo {
  display: none;
}

.nav-link {
  color: var(--clr-black);
  font-size: 1.5rem;
  font-weight: 1000;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.nav-link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.35rem;
  border-radius: 5px;
  bottom: -0.6rem;
  left: 0;
  background-color: var(--clr-green);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.nav-link:hover,
.nav-link:active {
  color: var(--clr-green);
}

.nav-logo {
  width: 125px;
  cursor: pointer;
  opacity: 0.85;
  transition: all 0.3s ease-in-out;
}

.nav-logo:hover {
  transform: scale(1.15);
}

.hamburger-menu-icon {
  border: solid 1.5px var(--clr-darkblue);
  color: var(--clr-darkblue);
  border-radius: 50%;
  padding: 0.5rem;
  width: 3.25rem;
  height: 3.225rem;
}

.hamburger-menu-icon,
.hamburger-close-icon {
  cursor: pointer;
}

.hamburger-menu-container,
.hamburger-close-icon {
  display: none;
}

/* ********** MEDIA QUERIES ********** */

@keyframes navlinksSlideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .navbar-wrapper {
    margin-bottom: 3rem;
  }

  .responsive-nav-logo {
    display: block;
    width: 35px;
    cursor: pointer;
    opacity: 0.8;
    position: absolute;
    left: 4rem;
    top: 2.55rem;
    transition: all 0.3s ease-in-out;
  }

  .responsive-nav-logo:hover {
    transform: scale(1.15);
  }

  .navbar-container :nth-child(3) {
    display: none;
  }

  .navbar-container {
    display: none;
  }

  .responsive-navbar {
    display: flex;
    width: 100%;
    background: var(--clr-darkblue);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 4.5rem;
    z-index: 1;
  }

  .nav-link {
    color: var(--clr-white);
  }

  .responsive-navbar {
    animation: navlinksSlideDown 1s ease-in-out;
  }

  .nav-link::after {
    background-color: var(--clr-yellow);
  }

  .nav-link:hover,
  .nav-link:active {
    color: var(--clr-yellow);
  }

  .hamburger-menu-container {
    display: flex;
    justify-content: right;
    padding: 3rem 4rem 0 0;
    z-index: 0;
  }

  .hamburger-close-icon {
    color: var(--clr-white);
    display: flex;
    position: absolute;
    right: 4rem;
    top: 3rem;
  }

  .hamburger-close-icon:hover,
  .hamburger-menu-icon:hover {
    transform: scale(1.15);
    transition: all 0.3s ease-in-out;
  }

  .hamburger-close-icon:hover {
    color: var(--clr-yellow);
  }

  .hamburger-menu-icon:hover {
    color: var(--clr-blue);
    border: solid var(--clr-green);
  }
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4vh 6vw;
  height: 40vh;
  background: var(--clr-blue);
  background: linear-gradient(
    to left top,
    #d3faefed 0%,
    #d3faf9ed 50%,
    #fbfbebed 100%
  );
}

.footer-images-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 25vh;
  width: 35%;
}

.footer-logo-container {
  width: fit-content;
}

.footer-logo {
  width: 85px;
  padding-left: 1rem;
  opacity: 0.85;
  transition: all 0.3s ease-in-out;
}

.footer-logo:hover {
  transform: scale(1.15);
}

.hero-socials {
  display: flex;
  gap: 0.75rem;
}

.hero-socials a {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1.5px var(--clr-black);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 1.5rem;
  color: var(--clr-black);
  transition: all 0.3s ease-in-out;
}

.hero-socials a:hover {
  border: var(--clr-green) solid 1.5px;
  color: var(--clr-green);
  transform: scale(1.15);
}

.footer-text {
  font-size: 1rem;
  line-height: 0;
  color: var(--clr-black);
}

.footer-links-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 25vh;
  width: 55%;
}

.footer-link {
  color: var(--clr-black);
  font-size: 1.2rem;
  transition: all 0.3s ease-in-out;
}

.footer-link:hover {
  font-size: 1.5rem;
  color: var(--clr-darkblue);
}

/* ********** MEDIA QUERIES ********** */

@media screen and (max-width: 768px) {
  .footer-container {
    height: 15vh;
  }

  .footer-images-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    width: 100%;
    height: 100%;
  }

  .footer-logo-container {
    position: relative;
  }

  .footer-logo-container::after {
    content: "|";
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: -3.25rem;
    font-size: 2rem;
    width: 0.25rem;
    height: 100%;
  }

  .footer-logo {
    width: 60px;
    padding-left: 0;
  }

  .footer-text {
    font-size: 1.25rem;
  }

  .footer-links-container {
    display: none;
  }

  .hero-socials {
    display: none;
  }
}

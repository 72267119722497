.card-container {
  display: flex;
  align-items: center;
  padding: 2.5rem;
  border-radius: 7.5px;
  background: var(--clr-white);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.card-number-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-left: 0.5vw;
  margin-right: 3vw;
}

.card-number {
  font-size: 4rem;
  font-weight: 900;
  color: var(--clr-white);
}

.card-text-container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 65%;
  align-items: flex-end;
}

.card-text-container h3 {
  font-size: 2.5rem;
  font-weight: 800;
  letter-spacing: 0.1rem;
}

.card-link-text {
  font-size: 1.25rem;
  font-weight: 1000;
  letter-spacing: 0.05rem;
}

.arrow-up-icon {
  color: var(--clr-blue);
  padding-top: 1.5px;
  font-size: 2rem;
  font-weight: 1000;
}

.card-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

.card-link:hover {
  scale: 1.2;
  opacity: 1;
}

/* ********** MEDIA QUERIES ********** */

@media screen and (max-width: 1200px) {
  .card-number-container {
    margin-right: 2vw;
  }

  .card-text-container h3 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .card-number-container {
    width: 80px;
    height: 80px;
    margin-right: 1vw;
  }

  .card-number {
    font-size: 3rem;
  }

  .card-text-container h3 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 900px) {
  .card-number-container {
    width: 60px;
    height: 60px;
  }

  .card-number {
    font-size: 2.5rem;
  }

  .card-text-container {
    text-align: right;
  }

  .card-text-container h3 {
    font-size: 1.25rem;
  }

  .card-link-text {
    font-size: 1.1rem;
    letter-spacing: 0;
  }

  @media screen and (max-width: 768px) {
    .card-container {
      flex-direction: column;
      text-align: center;
      width: 100%;
      padding: 2rem 0.5rem;
    }

    .card-text-container {
      gap: 3rem;
      width: 100%;
      align-items: center;
      margin: 3rem 0 0 0;
      padding: 0;
    }

    .card-number-container {
      margin: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .card-container {
    flex-direction: row;
    padding: 2.5rem;
  }

  .card-number-container {
    width: 85px;
    height: 85px;
    margin-left: 0.5vw;
    margin-right: 3vw;
  }

  .card-number {
    font-size: 4rem;
  }

  .card-text-container {
    gap: 4rem;
    width: 72.5%;
    align-items: flex-end;
  }

  .card-text-container h3 {
    font-size: 1.7rem;
    letter-spacing: 0.1rem;
  }
}

@media screen and (max-width: 375px) {
  .card-text-container h3 {
    font-size: 1.5rem;
  }
}

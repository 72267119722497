@keyframes mySlider {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37.5rem;
  overflow: hidden;
  position: relative;
}

.slide {
  border-radius: 7.5px;
  height: 100%;
  object-fit: cover;
  animation: mySlider 0.25s ease-in-out;
}

.slide-hidden {
  display: none;
}

.arrow-left,
.arrow-right {
  position: absolute;
  width: 25px;
  height: 25px;
  color: var(--clr-white);
  filter: drop-shadow(0 0 2.5px var(--clr-black));
  cursor: pointer;
  opacity: 0.85;
}

.arrow-left {
  left: 1rem;
}

.arrow-right {
  right: 1rem;
}

.indicators {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 1rem;
}

.indicator {
  background-color: var(--clr-white);
  height: 9.5px;
  width: 9.5px;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0 0 3px var(--clr-black);
  margin: 0 0.4rem;
  cursor: pointer;
  opacity: 0.85;
}

.indicator-inactive {
  background-color: var(--clr-darkblue);
  height: 7.5px;
  width: 7.5px;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0 0 3px var(--clr-black);
  margin: 0 0.4rem;
  cursor: pointer;
  opacity: 0.85;
}
